<template>
  <v-container>
    <v-img
      :src="require('@/assets/images/features-split-image-01.svg')"
      contain
      height="450px"
      style="position: absolute; bottom: 0; right: 0px"
    ></v-img>
    <v-row class="pt-10 mt-10" justify="center" no-gutters>
      <v-col cols="12" class="pb-10">
        <v-row justify="center" no-gutters>
          <v-col cols="4" class="text-h3 text-center font-weight-medium d-flex justify-center">Contacter notre équipe</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pb-10">
        <v-row justify="center" no-gutters>
          <v-col cols="4" class="body-2 text-center"
            >Faites nous savoir comment nous pouvons vous aider, nous reviendrons vers vous dans les plus bref délais.
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7" class="pb-10">
        <v-form ref="form" v-model="valid">
          <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
            <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
          </vue-element-loading>
          <v-row justify="center" no-gutters>
            <v-col cols="5" class="mx-1">
              <v-text-field
                v-model="fields.lastName"
                :rules="emptyRules"
                solo
                background-color="#e8e8e8"
                label="Nom"
                light
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="mx-1">
              <v-text-field
                v-model="fields.firstName"
                :rules="emptyRules"
                solo
                background-color="#e8e8e8"
                label="Prénom"
                light
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="mx-1">
              <v-text-field
                v-model="fields.company"
                :rules="emptyRules"
                solo
                background-color="#e8e8e8"
                label="Société"
                light
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="mx-1">
              <v-text-field
                v-model="fields.email"
                :rules="emailRules"
                type="email"
                solo
                background-color="#e8e8e8"
                label="Adresse email"
                light
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="mx-1">
              <v-text-field
                v-model="fields.phone"
                :rules="phoneRules"
                solo
                background-color="#e8e8e8"
                label="Numéro de téléphone"
                light
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="mx-1">
              <v-textarea
                v-model="fields.details"
                solo
                background-color="#e8e8e8"
                label="Comment pouvons-nous vous aider ?"
                auto-grow
                rows="10"
                light
              ></v-textarea>
            </v-col>
            <v-col cols="10" class="mx-1">
              <v-btn
                :disabled="!valid"
                class="mr-4 text-uppercase"
                :class="{ 'gradiant-market-digital-alternative': valid, black: !valid }"
                :loading="loading"
                block
                @click="validate"
              >
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";

import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Contact",

  mixins: [snackBar],
  components: { SemipolarSpinner },

  data() {
    return {
      valid: true,
      fields: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        details: "",
      },
      emptyRules: [(v) => !!v || "Ce champs est obligatoire"],
      emailRules: [
        (v) => !!v || "Ce champs est obligatoire",
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "L'adresse email doit être valide",
      ],
      phoneRules: [
        (v) => !!v || "Ce champs est obligatoire",
        (v) =>
          !v ||
          /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(v) ||
          "Le numéro de téléphone doit être valide",
      ],

      loading: false,
    };
  },

  methods: {
    async validate() {
      console.log(this.fields);
      this.loading = true;
      try {
        await this.$axios.post("/contact", this.fields);

        this.fields = {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phone: "",
          details: "",
        };
        this.displaySuccessSnack("Nous allons revenir très vite vers vous.");
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Une erreur s'est produite lors de l'envoi de votre message à notre équipe.`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
